/*
 * @Author: your name
 * @Date: 2022-01-10 14:39:02
 * @LastEditTime: 2022-12-19 10:24:03
 * @LastEditors: HuYuliu 10097355522qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\views\companyProfile\index.tsx
 */
import { defineComponent, onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { useRouter } from 'vue-router'
import './index.scss'
import carousel from '@/components/carousel'
import solutionData from './solutionData'
/**
 * 标题
 */
const headerTitle = defineComponent({
    props: {
        title: {
            type: String
        },
        conten: {
            type: String
        },
        right: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        index: {
            type: Number,
            default: () => {
                return 0
            }
        }
    },
    setup(props, cex) {
        return () => (
            <div class="solution-item-header" style={{ backgroundPosition: `0 ${props.index * 15}%` }}>
                <div style={{ justifyContent: props.right ? 'flex-end' : '' }} class="solution-item-header-title">
                    <div>
                        <p>{props.title}</p>
                        <span>{props.conten}</span>
                    </div>
                </div>
            </div>
        )

    },
});


export default defineComponent({
    props: {},
    components: { carousel },
    setup(props, ctx) {
        const $solution = ref();
        const $carousel = ref();
        const router = useRouter()
        const navigationShow = ref<Boolean>(false)
        const scrolloff = ref(true)

        onMounted(() => {
    
            if(router.currentRoute.value.query.id){
                $solution.value = router.currentRoute.value.query.id
                setTimeout(()=>{
                    onNavigation({id:$solution.value})
                },500)
                
            }
            
            window.onscroll = () => {
                
                if (router.currentRoute.value.path == '/solution' && scrolloff.value) {
                    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                    
                    if (scrollTop - $carousel.value.offsetHeight + 50 > 0) {
                        navigationShow.value = true
                    } else {
                        navigationShow.value = false
                        // location.hash = ''
                    }
                    for (let index in solutionData) {
                        const solution = document.getElementById('solution' + index)
                        const offsetTop = (solution?.offsetTop as number) + (solution?.offsetHeight as number)

                        if (scrollTop +50 > (solution?.offsetTop as number) && scrollTop + 50  < offsetTop) {
                            
                            $solution.value = "#solution" + index
                            // location.hash="#solution"+index
                            break;
                        }
                    }
                }

            }
        })
        onBeforeUnmount(() => {
            // 离开该页面需要移除这个监听的事件，不然会报错
            window.onscroll = null
        })

        interface WatchType {
            index?: number;
            id?: string;
        }
        watch(router.currentRoute,( newValue, oldValue ) => {
            onNavigation({id: newValue.query.id as string || newValue.hash });
        });
        
        let navigatTimeout:any = null;
        const onNavigation = ({id,index}: WatchType) => {
            $solution.value = id?id:"#solution" + index
            // location.hash="#solution"+index
            scrolloff.value = false
            let domScr = document.querySelector($solution.value)
            domScr && domScr.scrollIntoView({
                behavior: 'smooth'
            })

            if(navigatTimeout){
                clearTimeout(navigatTimeout)
            }
            navigatTimeout = setTimeout(() => {
                scrolloff.value = true
                navigationShow.value = true
            }, 1000)
        }
        return () => (
            <div class="solution">
                <div class="solution-carousel" ref={$carousel}>
                    <carousel />
                </div>
                {
                    solutionData.map((item, index) => {
                        return <div class="solution-body"  id={'solution' + index}>
                            <headerTitle title={item.title}  conten={item.conten} index={index} right={index % 2 != 0 ? true : false} />
                            {
                                item.datalist.map(value=>{
                                    return <div class="solution-body-content">
                                        <div>
                                            <h3>{value.title}</h3>
                                            <span>{value.text}</span>
                                        </div>
                                        <img src={value.img} alt="" />
                                    </div>
                                })
                            }
                            
                        </div>
                    })
                }
                <div class="solution-navigation" v-show={navigationShow.value}>
                    {
                        solutionData.map((item, index) => {
                            return <li class={{'active' : $solution.value == "#solution" + index}} onClick={() => onNavigation({index})}>
                                <div class='solution-navigation-indicator'><span></span></div>
                                <span>{item.title}</span>
                            </li>
                        })
                    }
                </div>
            </div>
        )
    },
});