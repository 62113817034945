/*
 * @Author: your name
 * @Date: 2022-01-18 15:02:21
 * @LastEditTime: 2022-12-16 13:32:32
 * @LastEditors: HuYuliu 10097355522qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\components\carousel\index.tsx
 */
/**
 * 轮播图
 */
import { defineComponent, onMounted, ref } from 'vue';
import './index.scss'
export default defineComponent({
    props: {},
    setup(props, cex) {
        const carouselIndex = ref<number>(0);
        const carouselChange = (index: number) => {
            carouselIndex.value = index;
        };

        const $carousel = ref();
        const next = () => {
            $carousel.value.next();
        };
        const prev = () => {
            $carousel.value.prev();
        };

        const mun = ['', '', '', ''];
        return () => (
            <div>
                <el-carousel ref={$carousel} interval={5000} onChange={carouselChange} indicator-position="none" arrow="never" height="480px">
                    {
                        mun.map(i => {
                            return <el-carousel-item>
                                {/* <h1 class="small">{'图片' + i + 1}</h1> */}
                                <img src={i} alt="" />
                            </el-carousel-item>
                        })

                    }
                    <div class="position">
                        <div class="position-body">
                            <div class="position-body-indicator">
                                <button type="button" onClick={prev} class="el-carousel__arrow el-carousel__arrow--left">
                                    <el-icon size={24}>
                                        <caret-left />
                                    </el-icon>
                                </button>
                                <button type="button" onClick={next} class="el-carousel__arrow el-carousel__arrow--right">
                                    <el-icon size={24}>
                                        <caret-right />
                                    </el-icon>
                                </button>
                                {
                                    mun.map((item,index) => {
                                        return <div>
                                            <i onClick={() => $carousel.value.setActiveItem(index)} class={index == carouselIndex.value ? 'active' : ''}></i>
                                        </div>
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </el-carousel>
            </div>
        )

    },
});